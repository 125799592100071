import Impayes from "@/components/releves/Impayes";
import MoyensDePaiements from "@/components/releves/MoyensDePaiements";
import TDRL from "@/components/releves/TDRL.vue";


export default [
    {
        path: '/releves/impayes',
        name: 'impayes',
        component: Impayes
    },
    {
        path: '/releves/moyen-paiement',
        name: 'moyen-paiement',
        component: MoyensDePaiements
    },
    {
        path: '/releves/tdrl',
        name: 'releveTdrl',
        component: TDRL
    }

]