<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex" style="width: 30%">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <a href="index.html" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="../assets/images/logo-colored.svg" alt="" height="44">
                        </span>
              <span class="logo-lg">
                            <img src="../assets/images/logo-colored.svg" alt="" height="44">
                        </span>
            </a>

            <a href="index.html" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="../assets/images/logo-colored.svg" alt="" height="44">
                        </span>
              <span class="logo-lg">
                            <img src="../assets/images/logo-colored.svg" alt="" height="44">
                        </span>
            </a>
          </div>

          <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                  id="topnav-hamburger-icon">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
          </button>
<!--          <span class="mt-2" style="padding: 10px">Annee fiscale</span>
          <Select2 class="mt-2" style="width: 50%" v-model="selectedAnnee" :options="listAnnees"
                   @select="selectAnnee($event)" @change="changeAnnee($event)"/>-->


          <!-- App Search-->

        </div>

        <div class="d-flex align-items-center">

          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              <i class="bx bx-search fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                 aria-labelledby="page-header-search-dropdown">
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                  </div>
                </div>
              </form>
            </div>
          </div>


          <div class="ms-1 header-item d-none d-sm-flex">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    data-toggle="fullscreen">
              <i class='bx bx-fullscreen fs-22'></i>
            </button>
          </div>


          <div v-if="this.user != null" class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="../assets/images/logo_klis.svg"
                                 alt="Header Avatar">
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{
                                    user.fullName
                                  }}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{
                                    user.groupe.nom
                                  }}</span>
                            </span>
                        </span>
            </button>
            <div v-if="user.entites.length ===0 && this.user.groupe.nom === 'Super Admin'"
                 class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">Bienvenue {{ user.fullName }} !</h6>
              <button v-for="collectivite in listEntite" v-bind:key="collectivite.id"
                      @click="selectEntite(collectivite)" class="dropdown-item"><i
                  class="mdi mdi-home text-muted fs-16 align-middle me-1"></i> <span
                  class="align-middle">{{ collectivite.nom }}</span></button>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="pages-profile.html"><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Profile</span></a>

              <a @click="logout" class="dropdown-item"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Se deconnecter</span></a>
              <h6 class="dropdown-header">Backoffice: v3.3.9 API: v3.4.2</h6>
            </div>
            <div v-if="user.entites.length > 0" class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">Bienvenue {{ user.fullName }} !</h6>
              <button v-for="collectivite in user.entites" v-bind:key="collectivite.id"
                      @click="selectEntite(collectivite)" class="dropdown-item"><i
                  class="mdi mdi-home text-muted fs-16 align-middle me-1"></i> <span
                  class="align-middle">{{ collectivite.nom }}</span></button>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="pages-profile.html"><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Profile</span></a>

              <a @click="logout" class="dropdown-item"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Se deconnecter </span></a>
              <h6 class="dropdown-header">Backoffice: v3.3.9 API: v3.4.2</h6>
            </div>
            <div v-else-if="user.ramifications.length > 0" class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">Bienvenue {{ user.fullName }} !</h6>
              <button @click="selectRamification(ramification)" v-bind:key="ramification.id"
                      v-for="ramification in user.ramifications" class="dropdown-item"><i
                  class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                  class="align-middle">{{ ramification.nom }}</span></button>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#"><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Profile</span></a>

              <a @click="logout" class="dropdown-item"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Se deconnecter</span></a>
              <h6 class="dropdown-header">Backoffice: v3.3.9 API: v3.4.2</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import EntiteRepository from "@/repository/EntiteRepository";
// import Select2 from 'vue3-select2-component';

export default {
  name: "HeaderComponent",
  data() {
    return {
      //navbarMenuHTML: document.querySelector(".navbar-menu").innerHTML,
      listAnnees: [],
      selectedAnnee: null,
      user: null,
      entiteRepo: new EntiteRepository(),
      userPermissions: null,
      listEntite: null,
      selectedCollectivite: null,
      selectedRamification: null
    }
  },
  computed: {},
  methods: {
    logout() {
      // Supprime l'utilisateur du localStorage
      localStorage.removeItem('user');
      localStorage.removeItem('selectedCollectivite');
      localStorage.removeItem('selectedRamification');
      localStorage.removeItem('collectivites');

      // Redirige vers la page de connexion
      this.$router.push('/login');


    },
    fiscaleListe(start) {
      const result = [];
      for (let i = start - 2; i <= start; i++) {
        result.push(i);
      }
      return result;
    },

    selectAnnee(val) {
      //this.selectedAnnee = val;
      //  console.log(val)
      // this.selectedAnnee = val;

      localStorage.setItem('selectedAnnee', JSON.stringify(val));

    },
    changeAnnee(val) {
      this.selectedAnnee = val;

      localStorage.setItem('selectedAnnee', JSON.stringify(val));
      this.store.dispatch('setAnnee', JSON.parse(localStorage.getItem('selectedAnnee')));
      //console.log(localStorage.getItem('selectedAnnee'))

      //this.$store.dispatch("setAnnee", val);
    },


    selectEntite(entite) {
      this.selectedCollectivite = entite
      localStorage.setItem('selectedCollectivite', JSON.stringify(this.selectedCollectivite));
      location.reload();

    },
    selectRamification(ramification) {
      this.selectedRamification = ramification
      localStorage.setItem('selectedRamification', JSON.stringify(this.selectedRamification));
      location.reload();

    },
  },
  created() {
    try {
      //    this.$store.dispatch("setCollectivite", JSON.parse(localStorage.getItem('selectedCollectivite')));
      this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
      this.user = this.$store.state.user;
      this.$store.dispatch("setUserPermissions", JSON.parse(localStorage.getItem('userPermissions')));
      this.userPermissions = this.$store.state.userPermissions;
      this.$store.dispatch("setAnnee", JSON.parse(localStorage.getItem('selectedAnnee')));
      this.selectedAnnee = this.$store.state.selectedAnnee;
      this.listAnnees = this.fiscaleListe(new Date().getFullYear());
      if (this.user.groupe.nom === 'Super Admin') {
        this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
            .then(response => {
              this.listEntite = response.data;
            })
            .catch(error => {
              console.log(error)
            })

      }

      console.log(this.$store.state.selectedAnnee)


      //    console.log(this.userPermissions[0].id)

      // this.selectedCollectivite = this.$store.state.selectedCollectivite;
      // console.log(JSON.parse(localStorage.getItem('setUserPermissions')))

    } catch (e) {
      console.log(e)

    }
  },
  mounted() {

  },

  components: {
    // Select2,
  }
}
</script>