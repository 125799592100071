import API from "@/helpers/Api";
import {trim} from "core-js/internals/string-trim";
export default class ReleveRepository extends API {
    impaye(param)
    {
        const impaye = this.post('/v2/contribuables/releve-impaye?entite='+param.entite+'&ramification='+param.ramification+'&activite='+param.activite+'&equipement='+param.equipement+'&from='+trim(param.from)+'&to='+trim(param.to), []);
        return impaye;
    }

    moyenPaiement(param)
    {
        const moyenPaiement = this.post('/v2/contribuables/releve-moyen_paiement?entite='+param.entite+'&ramification='+param.ramification+'&moyenPaiement='+param.moyenPaiement+'&from='+trim(param.from)+'&to='+trim(param.to), []);
        return moyenPaiement;
    }
    tdrl(param)
    {
        const moyenPaiement = this.post('/v2/contribuables/tdrl?entite='+param.entite+'&ramification='+param.ramification+'&from='+trim(param.from)+'&to='+trim(param.to), []);
        return moyenPaiement;
    }


}