import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class EquipementRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'eq.id' },
        { text: 'Collectivite', value: 'collectivite', name: 'ent.nom' },
        { text: 'Type d\'equipement', value: 'type', name: 'te.nom' },
        { text: 'Secteur', value: 'nom', name: 'ram.nom' },
        { text: 'Parent', value: 'departement', name: 'p.nom' },
        { text: 'Numero Klispay', value: 'numKlis', name: 'eq.numKlis' },
        { text: 'Date d\'enrollement', value: 'createdAt', name: 'eq.createdAt' },
        { text: 'enabled', value: 'enabled', name: 'eq.enabled' },
        ];

    create(data){
        const equipements = this.post('/v2/equipements', data)
        return equipements;
    }
    edit(data, id){
        const equipements = this.patch('/v2/equipements/'+id, data)
        return equipements;
    }
    find(id){
        const equipements = this.findOne('/v2/equipements/'+id)
        return equipements;
    }
    supprimer(id){
        const equipements = this.delete('/v2/equipements/'+id)
        return equipements;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const equipements = this.listingContrib('/v2/equipements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return equipements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const equipements = this.listingContrib('/v2/equipements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(equipements);
                return equipements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const equipements = this.listingContrib('/v2/equipements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(equipements);
                return equipements;
            }
        }
    }
    searchContrib(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const equipements = this.listing('/v2/equipements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return equipements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })

                const equipements = this.listing('/v2/equipements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(equipements);
                return equipements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const equipements = this.listing('/v2/equipements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(equipements);
                return equipements;
            }
        }
    }



}