




<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{selectedEntite.nom}} > Tous les contribuables</h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Statistiques</router-link></li>
            <li class="breadcrumb-item active">Recensement par activité</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <!--
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
          -->
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button  @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
          </div>
          <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
          </div>
        </div>
       <div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
          </div>
        </div>
        <br>
        <br>
        <div class="row" style="text-align: left">
          <div class="col-md-12">
           <strong>{{daterange}}</strong>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-12">
            <div class="card crm-widget">
              <div class="card-body p-0">
                <div class="row row-cols-xxl-4">
                  <div class="col text-start bg-warning">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13">Base taxables enrôlé(s) </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-user-3-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2><router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">{{nombreTotalEnrol}}</router-link></h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span class="counter-value" :data-target="report.contribuables"><router-link :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(this.report.contribuables) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <!-- <div class="col text-start bg-info">
                     <div class="py-4 px-3 text-white">
                       <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                       <div class="d-flex align-items-center">
                         <div class="flex-shrink-0">
                           <i class="ri-money-dollar-box-line display-6 text-white"></i>
                         </div>
                         <div class="flex-grow-1 ms-3">
                           <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>
                         </div>
                       </div>
                     </div>
                   </div>--><!-- end col -->
                  <div class="col text-start bg-success">
                    <div class="py-4 px-3">
                      <h5 class="text-uppercase fs-13 text-white">nombre base taxable validée(s) <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2><router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to, statut: 'Validé'} }">{{nombreValidation}}</router-link></h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span style="color: #fff" class="counter-value" :data-target="Number(report.valide)"><router-link class="text-white" :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.valide) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <div class="col text-start bg-danger">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13 text-white">Base taxable corrigée(s) </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2 class="text-white"><router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to, statut: 'Corrigé'} }">{{nombreCorrige}}</router-link></h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold text-white"><span class="counter-value" data-target="3"><router-link style="color: #fff" :to="{name : 'listeTransactions', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.totalNonValide )}}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                </div><!-- end row -->

              </div><!-- end card body -->
            </div><!-- end card -->
          </div><!-- end col -->
        </div>
          <div class="row">
              <div class="card card-animate mt-3">
                  <div class="card-body">
                      <div class="row right p-2">
                          <div class="col-md-2 mt-4">
                              <div v-if="user.groupe.nom === 'Super Admin'" class="btn-group " role="group">
                                  <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                                      Action
                                  </button>
                                  <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                                      <li><a  @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                                      <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                                  </ul>
                              </div>
                          </div>


                      </div>

                      <div class="row">
                          <div class="table-responsive">
                              <div class="col-sm-auto text-start">
                                  <div>
                                      <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i class="ri-file-pdf-fill align-bottom me-1"></i> PDF</button>
                                  </div>
                              </div>
                              <table id="element-to-convert" class="table text-start mb-0 table-bordered">
                                  <thead class="table-light">
                                  <tr class="text-center" >
                                      <th scope="col" class="bg-success">
                                          <div class="form-check">
                                              <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                                              <label class="form-check-label" for="responsivetableCheck"></label>
                                          </div>
                                      </th>
                                      <th class="bg-success" scope="col" >#</th>
                                      <th class="bg-success" scope="col">Nom</th>
                                      <th class="bg-success" scope="col">Nombre</th>
                                      <th class="bg-success" scope="col" >Corrigé</th>
                                      <th class="bg-success" scope="col" >Validé</th>


                                  </tr>

                                  </thead>
                                  <tbody>
                                  <tr v-for="(item, index) in listTaxe" :key="item.id">
                                      <td><input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" /></td>
                                      <td>{{ index+1 }}</td>
                                      <td>{{item.taxe}}</td>
                                      <td>{{item.total}}</td>
                                      <td>{{item.corrige}}</td>
                                      <td>{{item.valide}}</td>


                                      <td>
                                          <div class="dropdown">
                                              <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i class="ri-more-2-fill"></i>
                                              </a>

                                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                              </ul>
                                          </div>
                                      </td>
                                  </tr>

                                  </tbody>
                              </table>
                              <!-- end table -->
                          </div>
                      </div>
                      <!-- end table responsive -->
                  </div>
              </div>
          </div>

        <br>  <br>
        <br>
        <div id="chart">
          <apexchart type="line" height="300" :options="chartOptions()" :series="series()"></apexchart>
        </div>
        <br>






      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
//import Repository from '@/repository/NatureActiviteRepository'
import Repository from "@/repository/RamificationRepository";
import moment from "moment";
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';

import store from "@/store";
import RamificationRepository from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import fr from "flatpickr/dist/l10n/fr";
export default {
  name: 'listCategorieActivitePage',
  data() {
    return {

     /* series: [{
        name: 'Activité',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'njkijj',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Statistique activité'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Activité',
          },

        }, {
          opposite: true,
          title: {
            text: ',nkfj'
          }
        }]
      },*/



      listCollectivites: [],
      ids: [],
      ms: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      entite: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      qt: [],
      nombreTotalEnrol: 0,
      nombreValidation: 0,
      nombreCorrige: 0,
      secteur: null,
      nbrBaseTaxable: null,
      isLoading: false,
      fullPage: true,
      user: null,
      query: [],
      daterange: "",
      from: null,
      to: null,
      listTaxe: [],
      repository: new Repository(),
      orderBy: 'na.nom',
      sortOrder: 'asc',
      itemsPerPage: 100,
      selectedEntite: null,
      allSelected: false,
      selectedItems: [],

      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [

      ]
    }
  },
  computed: {
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },
  },
  methods: {
    series(){
      return [
        {
          name: 'Enrolements',
          type: 'column',
          data: this.listTaxe.map(item => item.total)
        },
        {
          name: 'Corrige',
          type: 'column',
          data:this.listTaxe.map(item => item.corrige)
        },
        {
          name: 'Valide',
          type: 'column',
          data:this.listTaxe.map(item => item.valide)
        },


      ]

    },
    chartOptions(){
      return {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Enrolement par base taxable'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: this.chartLabel(),
        xaxis: {
          type: 'category'
        },
        yaxis: [{
          title: {
            text: 'Enrolements',
          },

        },
          {
            opposite: true,
            title: {
              text: 'Enrolements'
            }
          }]
      }

    },
    chartLabel(){

      // var d = "";

      return this.listTaxe.map(item => item.taxe);
    },



    onDateChange(){
      this.refresh();
    },
    refresh(){
      if (this.entite === null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardByEntite(this.ids, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0];
            });
        this.dashboardRepo.dashboardDetailsByEntite(this.ids, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            });
      }
      if (this.entite != null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0];
            });
        this.dashboardRepo.dashboardDetailsByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            });
      }
      if (this.departement != null && (this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.departement.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.departement.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.quartier != null && (this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.quartier.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.quartier.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.zone != null && this.secteur === null)
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.zone.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.zone.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.secteur != null)
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.secteur.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.secteur.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
     this.fillTable();
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
      this.fillTable();
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      this.fillTable();
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();

            })
          });
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.ms = this.listQuartiers.map(item => item.id);
            this.fillTable();

          })
    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.ms = this.listZones.map(item => item.id);

            this.fillTable();
          })


    },
    contribuables() {
      return this.listContribuables.filter(item => item.statut='Validé');
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas:  { scale: 2 },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        filename: "liste-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a0', orientation: 'landscape' }
      });
    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            });
            this.ms = this.listSecteurs.map(item => item.id);
            this.fillTable();
          })

    },
    deleteNatureActivite(id){
      if(confirm("Etes-vous sûr de supprimer cet élément ?")){
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }


    },
    bulkDelete(){
      if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false){
        this.allSelected = true;
        this.selectedItems = this.listContribuables.map(type => type.id);
        //  console.log(this.selectedItems);
      }
      else{
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page){
      this.currentPage = page;
      this.$router.replace({ name: "listeContribuables",  query: {q1: "q1"} })
      this.fillTable();
    },
    addFields() {
      this.fields.push({ colonne: '', operator: '', value: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },

    handleItemPerPage(event){
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage=1;
      this.$router.replace({ name: "listeContribuables",  query: {itemsPerPage: event.target.value, orderBy: this.orderBy} })

      this.fillTable();


    },
    handleSearch(){
      //console.log(this.fields);
      this.fillTable();
    },
    handleOrderBy(event){
      this.$router.replace({ name: "listeContribuables",  query: {orderBy: event.target.value} })

      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event){
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    selectSecteur(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.ms = val.id;

            this.fillTable();
          })

    },
    async fillTable(){
      this.isLoading = true;
      this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
      this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[0];


      await this.repository.findOne("/v2/statistiques/enrollement/par-taxe?entite="+this.ids+'&ramification='+this.ms+"&from=" + this.from + "&to="+this.to)
          .then(response => {
            console.log(response);
            this.listTaxe = response;
            this.isLoading=false;
           // this.nbrBaseTaxable = this.listTaxe.reduce((accumulator,currentValue) => accumulator + currentValue.total, 0)
            this.nombreTotalEnrol = this.listTaxe.reduce((accumulator,currentValue) => accumulator + currentValue.total,0);
            this.nombreValidation = this.listTaxe.reduce((accumulator,currentValue) => accumulator += Number(currentValue.valide ?? 0),0);
            this.nombreCorrige = this.listTaxe.reduce((accumulator,currentValue) => accumulator += Number(currentValue.corrige ?? 0),0);
          });




    },


  },
  created() {
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[0];

    this.query = Object.values(this.$route.query);
    this.entite = {id: this.query[0]};
    this.departement = {id: this.query[1]};
    this.quartier = {id: this.query[2]};
    this.zone = {id:this.query[3]};
    this.secteur = {id: this.query[4]};

    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});
            this.fillTable();
          })
        })
  },
  components:{
    Loading,
    Select2


  }
}
</script>