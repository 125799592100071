import ListeEquipement from "@/components/equipement/ListeEquipement";


export default [

    {
        path: '/equipements',
        name: 'listeEquipements',
        component: ListeEquipement
    }
]