import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class TransactionRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'trans.id' },
        { text: 'Numero de transaction', value: 'trans.noTransaction', name: 'trans.noTransaction' },
        { text: 'Collectivite', value: 'collectivite', name: 'ent.id' },

        { text: 'Telephone', value: 'nom', name: 'contrib.telephone' },
        { text: 'Moyen de paiement', value: 'moyenpaiement', name: 'mp.id' },
        { text: 'Agent', value: 'agent', name: 'user.id' },
        { text: 'Nom Emplacement', value: 'Emplacement', name: 'ram.id' },
        { text: 'Emplacement', value: 'Emplacement', name: 'ram.nom' },
        //{ text: 'telephone', value: 'telephone', name: 'contrib.telephone' },
        { text: 'Raison sociale', value: 'raisonSociale', name: 'contrib.raisonSociale' },
        { text: 'Numero Klispay', value: 'numKlis', name: 'contrib.numKlis' },
        { text: 'Date de paiement', value: 'createdAt', name: 'trans.dateTransaction' },
        { text: 'statut', value: 'statut', name: 'trans.statut' },
    ];

    create(data){
        const transactions = this.post('/v2/contribuables/transactions', data)
        return transactions;
    }
    edit(data, id){
        const transactions = this.patch('/v2/transactions/'+id, data)
        return transactions;
    }
    find(id){
        const transactions = this.findOne('/v2/transactions/'+id)
        return transactions;
    }
    supprimer(id){
        const transactions = this.delete('/v2/transactions/'+id)
        return transactions;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const transactions = this.listing('/v2/transactions?filter[trans.statut][type]=eq&filter[trans.statut][x]=Confirmé', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return transactions;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const transactions = this.listing('/v2/transactions?filter[trans.statut][type]=eq&filter[trans.statut][x]=Confirmé&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(transactions);
                return transactions;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const transactions = this.listing('/v2/transactions?filter[trans.statut][type]=eq&filter[trans.statut][x]=Confirmé&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(transactions);
                return transactions;
            }
        }
    }
    situationRecouvrement(ramification, date){
        const transactions = this.findOne('/v2/transactions/'+ramification +'/recouvrement?daterange='+date)
        return transactions;
    }


}