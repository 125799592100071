import API from "@/helpers/Api";
import store from '@/store'
import User from "@/entity/User";

export default class RamificationRepository extends API {
    user = new User();

    orderByOptions= [
        { text: 'id', value: 'id', name: 'ram.id' },
        { text: 'nom', value: 'nom', name: 'ram.nom' },
        { text: 'code', value: 'code', name: 'co.nom' },
        { text: 'collectivité', value: 'collectivite', name: 'ent.nom' },
        { text: 'createdAt', value: 'createdAt', name: 'ram.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'ram.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'ram.enabled' },
    ];

    create(data){
        const ramifications = this.post('/v2/ramifications', data)
        return ramifications;
    }
    edit(data, id){
        const ramifications = this.patch('/v2/ramifications/'+id, data)
        return ramifications;
    }
    find(id){
        const ramifications = this.findOne('/v2/ramifications/'+id)
        return ramifications;
    }
    supprimer(id){
        const ramifications = this.delete('/v2/ramifications/'+id)
        return ramifications;
    }
    generateEcheance(id){
        const ramifications = this.findOne('/v2/echeances/generate-by-ramification?items='+id)
        return ramifications;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage) {

        this.user = store.state.user
        //console.log(this.user.ramifications)
        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const ramifications = this.listing('/v2/ramifications', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return ramifications;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const ramifications = this.listing('/v2/ramifications?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return ramifications;
            }
            if (this.user.ramifications.length >  0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.id)
                })
                const ramifications = this.listing('/v2/ramifications?filter[p.id][type]=in&filter[p.id][x]='+ids +'&filter[ram.id][type]=in&filter[ram.id][x]='+ids + '&filter[ram.id][connector]=or'+'&filter[pp.id][type]=in&filter[pp.id][x]='+ids + '&filter[pp.id][connector]=or'+'&filter[ppp.id][type]=in&filter[ppp.id][x]='+ids + '&filter[ppp.id][connector]=or'+'&filter[pppp.id][type]=in&filter[pppp.id][x]='+ids + '&filter[pppp.id][connector]=or' , fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return ramifications;
            }
        }

    }
    childs(fields, orderBy, sortOrder, itemPerPage, currentPage) {

        const ramifications = this.listing('/v2/ramifications'  , fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(ramifications);
        return ramifications;

    }




}