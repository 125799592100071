import ListRoles from "@/components/roles/ListRoles";
import RolePermissions from "@/components/roles/RolePermissions";


export default [

    {
        path: '/roles',
        name: 'listRoles',
        component: ListRoles
    },
    {
        path: '/roles/:id/permissions',
        name: 'rolePermissions',
        component: RolePermissions
    }
]